/* eslint-disable react-hooks/rules-of-hooks */
import React, { FC, ReactNode, useRef, useMemo } from 'react'
import {
  Image,
  MediaPlaybackCtaModel,
  Modal,
  Spacer,
} from '@heights/heights-ui-library'
import { ArticleHero, ContentHighlight } from '@heights/heights-ui-components'
import { INLINES } from '@contentful/rich-text-types'
import { RichTextContent } from '@/components/RichTextContent'
import { useTypeform, TYPEFORMS } from '@/hooks/useTypeform'
import { RenderNode } from '@contentful/rich-text-react-renderer'
import { Link } from '@theme-ui/components'
import { ensureInternalUrlTrailingSlash } from '@/utils'
import { Byline } from '@/components/Article/Byline'
import { TCta } from '@heights/heights-react-hooks'
import compact from 'just-compact'
import { useData, useOldCtaAction } from '@/hooks'
import { getReadingTime, transformImage } from '@/utils/contentful'
import { contentHighlightSection } from '@/schemas/contentHighlightSection'
import { learnHubAuthor as learnHubAuthorSchema } from '@/schemas/learnHubAuthor'
import { article as articleSchema } from '@/schemas/article'
import { mediaPlaybackCta as mediaPlaybackCtaSchema } from '@/schemas/mediaPlaybackCta'
import { ctaAction } from '@/schemas'
import { DataSourceType } from '@/hooks/useData'

export const [ctaActionQuery, ctaActionType] = ctaAction.builder
  .filter('_id == ^.fields.ctaAction._ref')
  .pick(['fields'])
  .first()
  .use()

export const [mediaPlaybackCtaQuery, mediaPlaybackCtaType] =
  mediaPlaybackCtaSchema.builder
    .filter('_id == $id')
    .map((cta) => ({
      image: cta.fields.image?.resolve(['fields']).use(),
    }))
    .first()
    .use()

const [, type] = contentHighlightSection.builder
  .map((section) => ({
    image: section.fields.image?.resolve(['fields']).use(),
    backgroundImage: section.fields.backgroundImage?.resolve(['fields']).use(),
    textItems: section.fields.textItems?.resolveIn(['fields']).use(),
  }))
  .subquery({
    ctaAction: [
      ctaActionQuery,
      ctaActionType as typeof ctaActionType | null | undefined,
    ],
  })
  .first()
  .use()

const [authorQuery, authorType] = learnHubAuthorSchema.builder
  .filter('_id == ^.fields.author._ref')
  .map((author) => ({
    profileImage: author.fields.profileImage?.resolve(['fields']).use(),
  }))
  .first()
  .use()

const [articleQuery, articleType] = articleSchema.builder
  .filter('_id == $id')
  .map((article) => ({
    category: article.fields.category?.resolve(['_id', 'fields']).use(),
    featuredImage: article.fields.featuredImage?.resolve(['fields']).use(),
  }))
  .subquery({
    author: [authorQuery, authorType],
  })
  .first()
  .use()

interface ContentHighlightRendererProps {
  data: Partial<
    Omit<
      NonNullable<(typeof type)['fields']>,
      | 'ctaAction'
      | 'image'
      | 'backgroundImage'
      | 'mediaPlayback'
      | 'contentAlignment'
      | 'textItems'
    >
  > & {
    type?: 'banner' | 'block'
    contentAlignment?: 'left' | 'right' | 'center'
    mobileContentAlignment?: 'default' | 'above' | 'below'
    ctaAction?: (typeof type)['ctaAction']
    image?: (typeof type)['image']
    backgroundImage?: (typeof type)['backgroundImage']
    ctaActions?: (typeof ctaActionType)[]
    mediaPlayback?: { _ref: string }
    textItems?: (typeof type)['textItems']
  }
  enabledFeatureFlags?: {
    buttonCta?: boolean
    reverseContent?: boolean
  }
}

export const ContentHighlightRenderer: FC<ContentHighlightRendererProps> = ({
  data,
  enabledFeatureFlags,
}) => {
  const [handleTypeForm] = useTypeform(TYPEFORMS.BHS)
  const modalRef = useRef(null)
  const mediaPlaybackReader = useData<typeof mediaPlaybackCtaType>(
    DataSourceType.CONTENTFUL,
    mediaPlaybackCtaQuery,
    {
      id: data.mediaPlayback?._ref ?? '',
    }
  )
  const linkedContentReader = useData<typeof articleType>(
    DataSourceType.CONTENTFUL,
    articleQuery,
    {
      id: data.ctaAction?.fields?.linkContent?._ref ?? '',
    }
  )
  const linkedContent = linkedContentReader()
  const mediaPlayback = mediaPlaybackReader()
  const image = useMemo(
    () =>
      transformImage(data?.image?.fields, {
        width: 1000,
        objectFit: 'contain',
      }),
    [data.image]
  )

  if (!data || !image) {
    return null
  }

  const openModal =
    (ref: React.MutableRefObject<any>) => (event: React.SyntheticEvent) => {
      ref.current.openPortal(event)
    }

  const renderModal = () => {
    if (!data.ctaAction || data.ctaAction.fields?.type !== 'modal') {
      return null
    }

    const renderNodeOverrides: RenderNode = {
      [INLINES.HYPERLINK]: ({ data: { uri } }, children): ReactNode => (
        <Link href={ensureInternalUrlTrailingSlash(uri)} target="_blank">
          {children}
        </Link>
      ),
    }

    const { author, featuredImage, category } = linkedContent ?? {}
    const { title, summary, content, publishedDate } =
      linkedContent?.fields ?? {}

    const transformedFeaturedImage = featuredImage
      ? transformImage(featuredImage.fields, {
          width: 1000,
        })
      : null
    const authorImage = author?.profileImage
      ? transformImage(author.profileImage?.fields, {
          width: 500,
        })
      : null

    return (
      <Modal variant="content" size="default" ref={modalRef} fullHeight={true}>
        {category?.fields?.title &&
          category?.fields.urlHandle &&
          title &&
          summary && (
            <ArticleHero
              category={{
                title: category.fields.title,
                urlHandle: category.fields.urlHandle,
              }}
              title={title}
              summary={summary}
            />
          )}
        <Spacer space={6} />
        {transformedFeaturedImage && (
          <Image
            {...transformedFeaturedImage}
            loading="eager"
            alt={transformedFeaturedImage.alt || ''}
          />
        )}
        {!!authorImage && (
          <Byline
            publishDate={publishedDate ?? ''}
            timeToRead={content ? getReadingTime(content).text : ''}
            authorName={author?.fields?.name ?? ''}
            roleTitle={author?.fields?.roleTitle ?? ''}
            urlHandle={author?.fields?.urlHandle ?? '/'}
            authorImage={authorImage}
          />
        )}
        <Spacer space={6} />
        {!!content && (
          <RichTextContent
            document={content}
            renderNodeOverrides={renderNodeOverrides}
          />
        )}
        <Spacer space={64} />
      </Modal>
    )
  }

  const callToActions = useOldCtaAction({
    ctaType: data?.ctaType as 'button' | 'link',
    ctaLink: data?.ctaLink,
    ctaText: data?.ctaText,
    ctaActions: compact(
      data.ctaActions?.map(({ fields }) => fields) ??
        compact([data.ctaAction?.fields])
    ).map((fields) => ({ fields })),
    mutate: (cta) => {
      switch (data.ctaAction?.fields?.type) {
        case 'modal': {
          cta.onClick = openModal(modalRef)
          break
        }
        case 'typeform-bhs':
        case 'typeform': {
          cta.onClick = handleTypeForm
          break
        }
        case 'openLiveChat': {
          cta.onClick = () => {
            window.GorgiasChat && window.GorgiasChat.open()
          }
          break
        }
      }

      if (!cta.style && enabledFeatureFlags?.buttonCta) {
        cta.style = 'primary'
      }
      return cta
    },
  })

  const items = useMemo(() => {
    return compact(
      data?.textItems?.map((entry) => {
        if (entry.fields?.icon) {
          return {
            title: entry?.fields?.title ?? '',
            icon: entry?.fields?.icon,
          }
        }
        return null
      }) ?? []
    )
  }, [data])

  return (
    <>
      {renderModal()}
      <ContentHighlight
        id={data.trackingId ?? ''}
        type={data.type}
        title={data.title ?? ''}
        content={
          data.contentHighlightContentRT && (
            <RichTextContent
              inheritParagraphFont
              document={data.contentHighlightContentRT}
            />
          )
        }
        callToActions={callToActions as TCta[]}
        image={image}
        mediaPlaybackData={
          mediaPlayback
            ? ({
                ...mediaPlayback.fields,
                image:
                  transformImage(mediaPlayback.image?.fields, {
                    width: 1000,
                  }) ?? undefined,
              } as MediaPlaybackCtaModel)
            : undefined
        }
        backgroundColor={data.backgroundColor ?? undefined}
        backgroundImage={
          transformImage(data.backgroundImage?.fields, {
            width: 1920,
            fullscreen: true,
          }) ?? undefined
        }
        contentAlignment={data?.contentAlignment}
        mobileContentAlignment={data?.mobileContentAlignment}
        ingredientUspItems={items?.length ? items : undefined}
      />
    </>
  )
}

export default ContentHighlightRenderer
