import { backgroundColors } from '@/contentful/utils/commonValues'
import { defineDocument, defineObject } from 'sanity-typed-queries'
import { mediaPlaybackCta } from './mediaPlaybackCta'
import { asset } from './asset'
import { ctaAction } from './ctaAction'
import { JsonType } from './JsonType'
import { textItem } from './textItem'

const contentHighlightSectionFields = defineObject(
  'contentHighlightSectionFields',
  {
    reference: { type: 'string' },
    trackingId: { type: 'string' },
    title: { type: 'string' },
    contentHighlightContentRT: { type: 'json' },
    ctaType: {
      type: 'string',
      options: { list: ['link' as const, 'button' as const] },
    },
    ctaText: { type: 'string' },
    ctaLink: { type: 'string' },
    ctaAction: { type: 'reference', to: [{ type: 'ctaAction' }] },
    contentAlignment: {
      type: 'string',
      options: { list: ['left' as const, 'right' as const] },
    },
    image: {
      type: 'reference',
      to: [{ type: 'asset' }],
    },
    backgroundColor: {
      type: 'string',
      options: { list: backgroundColors },
    },
    backgroundImage: {
      type: 'reference',
      to: [{ type: 'asset' }],
    },
    mediaPlayback: {
      type: 'reference',
      to: [{ type: 'mediaPlaybackCta' }],
    },
    textItems: {
      type: 'array',
      of: [{ type: 'reference', to: [{ type: 'textItem' }] }],
    },
  },
  [
    {} as JsonType,
    ctaAction.ctaAction,
    asset.asset,
    mediaPlaybackCta.mediaPlaybackCta,
    textItem.textItem,
  ]
)

export const contentHighlightSection = defineDocument(
  'contentHighlightSection',
  {
    fields: {
      type: 'contentHighlightSectionFields',
    },
  },
  [contentHighlightSectionFields.contentHighlightSectionFields]
)
