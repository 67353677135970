import { SectionFeatureFlags } from '@/contentful/utils/commonValues'
import React from 'react'
import { withErrorBoundary } from '@/utils/withErrorBoundary'
import {
  ContentHighlightRenderer,
  ctaActionQuery,
  ctaActionType,
} from './ContentHighlightRenderer'
import { SingleComponentPickerItem } from '../../SingleComponentPicker'
import { useData, useSectionData } from '@/hooks'
import { contentHighlightSection } from '@/schemas/contentHighlightSection'
import { DataSourceType } from '@/hooks/useData'
import { IconNames } from '@heights/heights-icons'

type Props = {
  data: SingleComponentPickerItem
}

const [query, type] = contentHighlightSection.builder
  .filter('_id == $id')
  .map((section) => ({
    image: section.fields.image?.resolve(['fields']).use(),
    backgroundImage: section.fields.backgroundImage?.resolve(['fields']).use(),
    textItems: section.fields.textItems?.resolveIn(['fields']).use(),
  }))
  .subquery({
    ctaAction: [
      ctaActionQuery,
      ctaActionType as typeof ctaActionType | null | undefined,
    ],
  })
  .first()
  .use()

export const ContentfulContentHighlightSection: React.FunctionComponent<Props> =
  withErrorBoundary(({ data }) => {
    const section = useSectionData(data._id)
    const contentHighlightReader = useData<typeof type>(
      DataSourceType.CONTENTFUL,
      query,
      { id: data._id }
    )
    const contentHighlight = contentHighlightReader()

    const mediaPlayback = React.useMemo(() => {
      const items =
        section?.components?.filter(
          (item) => item?._type === 'mediaPlaybackCta'
        ) ?? []
      if (items.length) {
        return items[0]
      }
      return null
    }, [section])

    const textItems = React.useMemo(() => {
      const items =
        section?.components?.filter((item) => item?._type === 'textItem') ?? []
      if (items.length) {
        return items
      }
    }, [section])

    const enabledFeatureFlags = React.useMemo(() => {
      return {
        isBlock: !!section?.fields?.enabledFeatureFlags?.includes(
          SectionFeatureFlags.BASIC_SECTION__BLOCK
        ),
        buttonCta: !!section?.fields?.enabledFeatureFlags?.includes(
          SectionFeatureFlags.CONTENT_HIGHLIGHT__BUTTON_CTA
        ),
        reverseContent: !!section?.fields?.enabledFeatureFlags?.includes(
          SectionFeatureFlags.CONTENT_HIGHLIGHT__REVERSE_CONTENT
        ),
        mobileAlignment: !!section?.fields?.enabledFeatureFlags?.includes(
          SectionFeatureFlags.CONTENT_HIGHLIGHT__MOBILE_ABOVE_CONTENT
        ),
      }
    }, [section])

    if (contentHighlight) {
      return (
        <ContentHighlightRenderer
          data={{
            ...contentHighlight.fields,
            textItems: contentHighlight.textItems,
            image: contentHighlight.image,
            backgroundImage: contentHighlight.backgroundImage,
            ctaAction: contentHighlight.ctaAction,
          }}
        />
      )
    }

    if (section) {
      return (
        <ContentHighlightRenderer
          enabledFeatureFlags={enabledFeatureFlags}
          data={{
            type: enabledFeatureFlags.isBlock ? 'block' : 'banner',
            reference: section.fields?.reference ?? undefined,
            trackingId: section.fields?.trackingId ?? undefined,
            title: section.fields?.title ?? undefined,
            contentHighlightContentRT: section.fields?.description
              ? section.fields?.description
              : undefined,
            ctaAction: section.ctaAction ? { fields: section.ctaAction } : null,
            ctaActions:
              section.ctaActions?.map((action) => ({
                fields: action,
              })) ?? [],
            backgroundColor: section.fields?.backgroundColor ?? undefined,
            image: section?.backgroundImage,
            mediaPlayback: mediaPlayback ?? undefined,
            contentAlignment:
              section.fields?.alignment === 'center'
                ? ('center' as const)
                : enabledFeatureFlags.reverseContent
                ? 'right'
                : 'left',
            mobileContentAlignment: enabledFeatureFlags.mobileAlignment
              ? 'above'
              : 'below',
            // @fix: Fix type
            textItems: textItems?.map((item) => ({
              fields: item?.fields
                ? {
                    ...item.fields,
                    _type: 'textItemFields',
                    icon: item.fields.icon as IconNames | undefined,
                  }
                : undefined,
            })),
          }}
        />
      )
    }
    return null
  })

export default ContentfulContentHighlightSection
